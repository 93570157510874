import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import * as icons from 'icons';
import * as internal from 'icons/internal';
import { Flex } from 'components/Flex';
import { Paper } from 'components/Paper';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { IconLooper } from './helpers/IconLooper';
import { ThemeWrapper } from './helpers/ThemeWrapper';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <Playground __position={0} __code={'<ThemeWrapper>\n  <Flex wrap>\n    <IconLooper icons={icons}>\n      {(name, Icon) => (\n        <Rhythm key={name} m={2} px={4} py={2}>\n          <Paper bordered color=\"secondary\">\n            <Typography\n              size=\"large\"\n              variants={[\'letter-spacing-comfy\']}\n              volume=\"quiet\"\n              weight=\"light\"\n            >\n              {name}\n            </Typography>\n            <Flex alignItems=\"center\">\n              <Rhythm m={1}>\n                <Icon scale=\"xsmall\" />\n                <Icon scale=\"small\" />\n                <Icon scale=\"medium\" />\n                <Icon scale=\"large\" />\n                <Icon scale=\"xlarge\" />\n                <Icon scale=\"2xlarge\" />\n                <Icon scale=\"3xlarge\" />\n              </Rhythm>\n            </Flex>\n          </Paper>\n        </Rhythm>\n      )}\n    </IconLooper>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      icons,
      internal,
      Flex,
      Paper,
      Rhythm,
      Typography,
      IconLooper,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex wrap mdxType="Flex">
      <IconLooper icons={icons} mdxType="IconLooper">
        {(name, Icon) => <Rhythm key={name} m={2} px={4} py={2} mdxType="Rhythm">
            <Paper bordered color="secondary" mdxType="Paper">
              <Typography size="large" variants={['letter-spacing-comfy']} volume="quiet" weight="light" mdxType="Typography">
                {name}
              </Typography>
              <Flex alignItems="center" mdxType="Flex">
                <Rhythm m={1} mdxType="Rhythm">
                  <Icon scale="xsmall" mdxType="Icon" />
                  <Icon scale="small" mdxType="Icon" />
                  <Icon scale="medium" mdxType="Icon" />
                  <Icon scale="large" mdxType="Icon" />
                  <Icon scale="xlarge" mdxType="Icon" />
                  <Icon scale="2xlarge" mdxType="Icon" />
                  <Icon scale="3xlarge" mdxType="Icon" />
                </Rhythm>
              </Flex>
            </Paper>
          </Rhythm>}
      </IconLooper>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <div style={{
      height: '50px'
    }} />
    <h2 {...{
      "id": "internal-icons"
    }}>{`Internal icons`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Flex wrap>\n    <IconLooper icons={internal}>\n      {(name, Icon) => (\n        <Rhythm key={name} m={2} px={4} py={2}>\n          <Paper bordered color=\"secondary\">\n            <Typography\n              size=\"large\"\n              variants={[\'letter-spacing-comfy\']}\n              volume=\"quiet\"\n              weight=\"light\"\n            >\n              {name}\n            </Typography>\n            <Flex alignItems=\"center\">\n              <Rhythm m={1}>\n                <Icon scale=\"xsmall\" />\n                <Icon scale=\"small\" />\n                <Icon scale=\"medium\" />\n                <Icon scale=\"large\" />\n                <Icon scale=\"xlarge\" />\n                <Icon scale=\"2xlarge\" />\n                <Icon scale=\"3xlarge\" />\n              </Rhythm>\n            </Flex>\n          </Paper>\n        </Rhythm>\n      )}\n    </IconLooper>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      icons,
      internal,
      Flex,
      Paper,
      Rhythm,
      Typography,
      IconLooper,
      ThemeWrapper,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex wrap mdxType="Flex">
      <IconLooper icons={internal} mdxType="IconLooper">
        {(name, Icon) => <Rhythm key={name} m={2} px={4} py={2} mdxType="Rhythm">
            <Paper bordered color="secondary" mdxType="Paper">
              <Typography size="large" variants={['letter-spacing-comfy']} volume="quiet" weight="light" mdxType="Typography">
                {name}
              </Typography>
              <Flex alignItems="center" mdxType="Flex">
                <Rhythm m={1} mdxType="Rhythm">
                  <Icon scale="xsmall" mdxType="Icon" />
                  <Icon scale="small" mdxType="Icon" />
                  <Icon scale="medium" mdxType="Icon" />
                  <Icon scale="large" mdxType="Icon" />
                  <Icon scale="xlarge" mdxType="Icon" />
                  <Icon scale="2xlarge" mdxType="Icon" />
                  <Icon scale="3xlarge" mdxType="Icon" />
                </Rhythm>
              </Flex>
            </Paper>
          </Rhythm>}
      </IconLooper>
    </Flex>
  </ThemeWrapper>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      